import React from 'react';
import Icon from '../icon';

import InfoSection from '../info-section';

import './style.scss';

const amenities = [
  {
    name: 'free-parking',
    text: 'Free Parking*',
  },
  {
    name: 'no-smoking',
    text: 'No Smoking',
  },

  {
    name: 'internet-access',
    text: 'Internet Access',
  },
  {
    name: 'wifi',
    text: 'Wi-Fi',
  },
  {
    name: 'ac',
    text: 'Air conditioning',
  },
  {
    name: 'heat',
    text: 'Heating',
  },
  {
    name: 'shower',
    text: 'Shower',
  },
  {
    name: 'hair-dryer',
    text: 'Hairdryer',
  },
  {
    name: 'towel',
    text: 'Linen & Towels',
  },
  {
    name: 'bottle',
    text: 'Toiletries',
  },
  {
    name: 'steam',
    text: 'Steamer',
  },
  {
    name: 'coffee',
    text: 'Keurig Coffee Maker',
  },
  {
    name: 'mini-fridge',
    text: 'Mini-Fridge',
  },
  {
    name: 'smart-tv',
    text: 'Smart TV',
  },
];

const RoomAmenities = () => {
  return (
    <section className="section">
      <h2>Room Amenities</h2>
      <hr />
      <p>
        The Edward goes above and beyond the essentials. Each room and suite is
        equipped with a variety of luxurious amenities to make your stay as
        convenient and enjoyable as possible.
      </p>
      <InfoSection
        align="right"
        title="All Rooms"
        name="room-amenities"
        color="brown"
      >
        <h3>All Rooms</h3>
        <hr className="gray" />
        <div className="room-amenities__list">
          {amenities.map((item) => (
            <div className="room-amenities__amenity">
              <Icon name={item.name} />
              {item.text}
            </div>
          ))}
        </div>
        <p className="room-amenities__footnote">
          * Please note that parking is first-come, first-serve. Additionally,
          there is free street parking, as well as a paid parking ramp across
          the street.
        </p>
      </InfoSection>
    </section>
  );
};

export default RoomAmenities;
