import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./style.scss";

const CommonSpace = () => {
  return (
    <div>
      <section className="section">
        <h2>Roosevelt Tea Room</h2>
        <hr />
        <p>
          Cozy up by the fire in our beautiful tea room. If not by fireplace,
          the Roosevelt Tea Room also includes two tables where you can enjoy
          your meals and two couches to relax on while sipping a cocktail,
          reading one of our books about the history of Buffalo, or simply
          unwinding before heading out to the city.
        </p>
        <div className="common-space__grid">
          <StaticImage
            src="../../images/amenities/01.jpg"
            alt="The Edward Hotel Shared Space Photo"
            placeholder="blurred"
          />
          <StaticImage
            src="../../images/amenities/02.jpg"
            alt="The Edward Hotel Shared Space Photo"
            placeholder="blurred"
          />
          <StaticImage
            src="../../images/amenities/03.jpg"
            alt="The Edward Hotel Shared Space Photo"
            placeholder="blurred"
          />
          <StaticImage
            src="../../images/amenities/04.jpg"
            alt="The Edward Hotel Shared Space Photo"
            placeholder="blurred"
          />
        </div>
      </section>
      <section className="section">
        <h2>Solarium</h2>
        <hr />
        <p>
          Just off the Roosevelt Tea Room is the Solarium. The large windows on
          three sides will make you feel as if you are stepping into warm and
          lavish sunlight. It’s the perfect location to lie back and watch the
          sun rise over the city. The Solarium is equipped with an ice machine,
          water cooler, tea, and light refreshments for your comfort while you
          sit a while.
        </p>
        <div className="common-space__single-image">
          <StaticImage
            src="../../images/amenities/05.jpg"
            alt="The Edward Hotel Shared Space Photo"
            placeholder="blurred"
          />
        </div>
      </section>
    </div>
  );
};

export default CommonSpace;
