import React from "react";
import { Link } from "gatsby";

import InfoSection from "../info-section";

import "./style.scss";

const CheckIn = () => {
  return (
    <section className="section">
      <h2>Contactless Check-in</h2>
      <hr />
      <p>
        For your safety and ease, The Edward offers a contactless check-in and
        check-out experience.
      </p>
      <InfoSection
        align="right"
        title="All Rooms"
        name="check-in"
        color="green"
      >
        <div className="check-in__instructions">
          <div className="check-in__step">
            <div className="check-in__number">1</div>
            <div className="check-in__item">
              <h4>Book your reservation online.</h4>
              <p>
                Choose your dates, <Link to="/rooms">choose your room</Link>,
                and confirm your stay with us.
              </p>
            </div>
          </div>
          <div className="check-in__step">
            <div className="check-in__number">2</div>
            <div className="check-in__item">
              <h4>Look out for our text.</h4>
              <p>
                On your check-in day, we’ll send important information like
                parking instructions and your door code straight to your phone.
              </p>
            </div>
          </div>
          <div className="check-in__step">
            <div className="check-in__number">3</div>
            <div className="check-in__item">
              <h4>Enjoy your stay!</h4>
              <p>
                No keys and no cards to return at checkout. You’ll have all you
                need to enjoy your stay at The Edward.
              </p>
            </div>
          </div>
        </div>
      </InfoSection>
    </section>
  );
};

export default CheckIn;
