import * as React from "react";
import CommonSpace from "../components/common-space";
import CheckIn from "../components/check-in";
import Hero from "../components/hero";
import Layout from "../components/layout";
import RoomAmenities from "../components/room-amenities";

import "../styles/global.scss";

const AmenitiesPage = () => {
  return (
    <Layout title="Amenities">
      <Hero
        title="Amenities"
        text="We offer a variety of amenities for your convenience and pleasure."
        page="amenities"
      />
      <div className="container">
        <CommonSpace />
        <RoomAmenities />
        <CheckIn />
      </div>
    </Layout>
  );
};

export default AmenitiesPage;
